import React from "react"
import PropTypes from "prop-types"
import { Segment, Header, Grid, Container } from "semantic-ui-react"
import Layout from "@src/components/layout"
import { graphql } from "gatsby"

const PageNotFound = ({ data }) => {
  return (
    <Layout
      title={data.site.siteMetadata.title}
      heroImage={data.hero ? data.hero.heroBackgroundImage.fluid : null}
      heroVideo={data.hero ? data.hero.heroBackgroundVideo : null}
      heroText={data.hero ? data.hero.heroText : ""}
      heroTextPosition={data.hero ? data.hero.heroTextPosition : "center"}
      strapline={data.hero ? data.hero.strapline : null}
    >
      <Segment basic textAlign="center" style={{ padding: "6em 0" }}>
        <Grid as={Container} stackable>
          <Grid.Row>
            <Grid.Column only="tablet computer" tablet={1} computer={1} />
            <Grid.Column tablet={14} computer={14}>
              <Header as="h2">
                Oops, we couldn&apos;t find the page you are looking for
              </Header>
            </Grid.Column>
            <Grid.Column only="tablet computer" tablet={1} computer={1} />
          </Grid.Row>
        </Grid>
      </Segment>
    </Layout>
  )
}

PageNotFound.defaultProps = {
  data: false,
}

PageNotFound.propTypes = {
  data: PropTypes.shape({
    hero: PropTypes.shape({
      heroBackgroundImage: PropTypes.instanceOf(Object),
      heroBackgroundVideo: PropTypes.instanceOf(Object),
      heroText: PropTypes.string,
      heroTextPosition: PropTypes.string,
      strapline: PropTypes.string,
      title: PropTypes.string,
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
  }),
}

export default PageNotFound

export const query = graphql`
  {
    hero: datoCmsHeroBanner(title: { eq: "Page Not Found" }) {
      heroText
      heroTextPosition
      title
      heroBackgroundImage {
        fluid(maxWidth: 1920, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      heroBackgroundVideo {
        url
      }
      strapline
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
